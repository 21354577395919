import axios from 'axios'
import md5 from 'js-md5'
import { Toast } from 'vant'

const service = axios.create({
    baseURL: 'http://h5-api.hpzmangqu.cn/v1/web',
    // baseURL: 'http://dating-h5-api-test.hdsv.cn/v1/web',
    timeout: 60000,
})

service.interceptors.response.use(response => {
    const res = response.data
    if (!res.success) {
        Toast.fail(res.error.message)

        return Promise.reject()
    }

    // 如果接口正常，直接返回数据
    return res.payload
}, (error) => {
    if (error.name == 'Error') {
        Toast.fail(error.msg)
    } else {
        Toast.fail(error.response.data.data || error.message)
    }

    return Promise.reject(error)
})

export default service

export const doRequest = (path, params, query) => {
    let commonHeader = {
        appBundleId: query['appBundleId'],
        appVersion: query['appVersion'],
        appChannel: query['appChannel'],
        userId: query['userId'],
        token: query['token'],
        phoneModel: query['phoneModel'],
        phoneDeviceId: query['phoneDeviceId'],
        phoneOsType: query['phoneOsType'],
        phoneOsVersion: query['phoneOsVersion'],
    }
    return service({
        url: path,
        method: 'post',
        data: params,
        headers: getHeader(commonHeader),
    })
}

const getHeader = (h) => {
    const now = parseInt((new Date()).getTime() / 1000, 0)
    let key = now + ''
    key = key.split('').reverse().join('')

    const mnonce = md5(Math.floor(Math.random() * 10000000) + '')
    const msign = md5(mnonce + key)

    h['Content-Type'] = 'application/json'
    h['timestamp'] = now
    h['nonce'] = mnonce
    h['sign'] = msign

    return h
}
