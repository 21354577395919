<template>
    <div class="main">
        <div class="backgroup"></div>
        <img class="confirm" src="../../assets/active_confirm.png" @click="confirmJoin()" />
    </div>
</template>

<script>
import { doRequest } from '@/utils/request'
import { Toast } from 'vant'

export default {
    data() {
        return {
            d: {}
        }
    },
    methods: {
        confirmJoin() {
            doRequest('/encourageActive/confirmJoin', {}, this.$route.query).then(res => {
                this.d = res
                Toast.success('成功参与')
            })
        }
    }
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 375px;
    height: 812px;
}

.backgroup {
    background: url("../../assets/active.png") no-repeat;
    height:100%;
    width:375px;
    overflow: hidden;
    background-size:cover;
    margin-top: -150px;
}

.confirm {
    width: 200px;
    margin-top: -150px;
}
</style>